import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Button from "../components/elements/button"
import OnlineShopSlider from "../components/home/online-shop-slider"
import HomeSlider from "../components/sliders/common"
import { ArrowLeftIcon, ArrowRightIcon, XIcon } from "@heroicons/react/solid"
import Fade from "react-reveal/Fade"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useState } from "react"
import InquiryForm from "../components/home/inquiry-form"
import { Zoom } from "react-reveal"

const Home = ({ data }) => {
  const [success, setSuccess] = useState(false)
  const [modal, setModal] = useState(false)

  const images = {
    hero: convertToBgImage(getImage(data.hero)),
    privateevent: convertToBgImage(getImage(data.privateevent)),
    coffee_chat: convertToBgImage(getImage(data.coffee_chat)),
    head1: convertToBgImage(getImage(data.head1)),
    head2: convertToBgImage(getImage(data.head2)),

    // online shop
    grid1: getImage(data.grid1),
    grid2: getImage(data.grid2),
    grid3: getImage(data.grid3),
    grid4: getImage(data.grid4),
    grid5: getImage(data.grid5),
    grid6: getImage(data.grid6),
    grid7: getImage(data.grid7),
    grid8: getImage(data.grid8),


    // online order
    online01: getImage(data.online01),
    online02: getImage(data.online02),
    online03: getImage(data.online03),
    online04: getImage(data.online04),
    online05: getImage(data.online05),
    online06: getImage(data.online06),
    online07: getImage(data.online07),
    online08: getImage(data.online08),
    online09: getImage(data.online09),
    online10: getImage(data.online10),
    online11: getImage(data.online11),
    online12: getImage(data.online12),
    online13: getImage(data.online13),
    //  online14: getImage(data.online14),
    //  online15: getImage(data.online15),
  }

  const gallery = [
    { image: images.grid1 },
    { image: images.grid2 },
    { image: images.grid3 },
    { image: images.grid4 },
    { image: images.grid5 },
  ]

  const headerImage = [
    {
      image: images.head1,
      word_1: "Coffee",
      word_2: "and",
      word_3: "Community",
      description:
        "What do we love more than great coffee? Surrounding ourselves with friends and fellow coffee lovers! Strengthening our community by immersing ourselves in it and bring amazing coffee and music with us is our passion. You will be welcomed with open arms, the best service and some out of this world coffee in March 2023! ​We hope you are as excited as we are.",
    },
    {
      image: images.head2,
      word_1: "Coffee",
      word_2: "and",
      word_3: "Community",
      description:
        "What do we love more than great coffee? Surrounding ourselves with friends and fellow coffee lovers! Strengthening our community by immersing ourselves in it and bring amazing coffee and music with us is our passion. You will be welcomed with open arms, the best service and some out of this world coffee in March 2023! We hope you are as excited as we are.",
    },
  ]

  const onlineShop = [
    {
      image: images.online01,
      large: true,
    },
    {
      image: images.online02,
    },
    {
      image: images.online03,
    },
    {
      image: images.online04,
    },
    {
      image: images.online05,
    },
    {
      image: images.online06,
    },
    {
      image: images.online07,
    },
  ]

  const sliderImages = [
    {
      image: images.hero,
    },
    {
      image: images.privateevent,
    },
  ]

  function toastHandleChange(newValue) {
    setSuccess(newValue)
    setModal(false)
  }

  const notify = () => {
    toast.success("Thank you for your inquiry. We will get back to you soon.")
  }

  if (success) {
    notify()
    setTimeout(function () {
      setSuccess(false)
    }, 5000)
  }
  return (
    <Layout>
      <Seo title="Home" />
      <section>
        {/* <BackgroundImage {...images.hero} className="bg-primary-green">
          <div className="h-screen ">
            <div className="flex items-center justify-center h-full">
              <div>
                <div className="lg:text-8xl text-7xl   text-white text-center font-prata md:flex gap-6 justify-center ">
                  <Fade delay={500}>
                    <div>
                      <div>Coffee . </div>
                    </div>
                  </Fade>

                  <Fade delay={1000}>
                    <div>
                      <div> Art .</div>
                    </div>
                  </Fade>
                  <Fade delay={1500}>
                    <div>
                      <div>Music</div>
                    </div>
                  </Fade>
                </div>
                <Fade bottom>
                  <div className="text-white font-light text-sm md:max-w-3xl max-w-sm mx-auto text-center mt-4 sm:px-0 px-10">
                    Community, Coffee, Art and Music is what Vault Coffee House
                    is focused on. With our doors opening in 2022 in order to
                    help bring together community while serving only the best
                    locally roasted coffee.
                  </div>
                </Fade>
                <Fade bottom>
                  <div className="flex justify-center mt-7">
                    <Button text="Order Online" filledWithScale />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </BackgroundImage> */}

        <HomeSlider images={headerImage} header />
      </section>
      <section>
        <div className="container mx-auto md:px-20 px-10 py-24">
          <div className="w-full lg:flex gap-32 items-center overflow-hidden">
            <Fade left>
              <div className="lg:w-1/2 w-full">
                <StaticImage
                  src="../images/our_story.png"
                  alt="The Vault Coffeehouse"
                />
              </div>
            </Fade>

            <div className="lg:w-1/2 w-full">
              <div className="lg:text-left text-center">
                <Fade bottom cascade>
                  <div>
                    <div className="font-prata md:text-5xl text-4xl text-black mb-12 lg:mt-0 mt-12 ">
                      Our story
                    </div>
                  </div>
                  <div>
                    <div className="font-light text-base mb-6">
                      We thrive when people bond over our splendid coffee,
                      scrumptious eats and great music, and that is what Vault
                      Coffeehouse​ stands for. The joy these elements bring to
                      our community and the extent to which they can make you
                      appreciate ‘living in the moment’ and be grateful for the
                      little things in life, are unmatched
                    </div>
                  </div>
                  <div>
                    <div className="font-light text-base mb-14">
                      Community and Coffee is only a cup 2/3’s full without the
                      joy of some harmonious tunes. As such, we strive to bring
                      the best local artists and discover upcoming stars amongst
                      our midst to serenade us with their glorious tunes and
                      lively presence. If this sounds like you or someone you
                      may know, do come along and vibe with us!
                    </div>
                  </div>
                  <div>
                    <div>
                      <Link to="/about/">
                        <Button
                          text="Learn More"
                          bordered
                          color="primary-brown  "
                        />{" "}
                      </Link>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="container mx-auto px-20 pb-24">
          <div className="">
            <div className="font-prata text-5xl text-black mb-12">
              Our services
            </div>
            <div className="grid grid-cols-3 gap-20">
              <div>
                <div className="flex justify-center">
                  <StaticImage
                    src="../images/icons/online.png"
                    width={100}
                    alt="The Vault Coffeehouse"
                  />
                </div>
                <div className=" text-center text-3xl  font-semibold	my-5">
                  Online ordering
                </div>
                <p className="font-light text-base text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                </p>
              </div>
              <div>
                <div className="flex justify-center">
                  <StaticImage
                    src="../images/icons/events.png"
                    width={100}
                    alt="The Vault Coffeehouse"
                  />
                </div>
                <div className=" text-center text-3xl  font-semibold	my-5	">
                  Private Events
                </div>
                <p className="font-light text-base text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                </p>
              </div>
              <div>
                <div className="flex justify-center">
                  <StaticImage
                    src="../images/icons/entertainment.png"
                    width={100}
                    alt="The Vault Coffeehouse"
                  />
                </div>
                <div className=" text-center text-3xl  font-semibold	my-5	">
                  Entertainment
                </div>
                <p className="font-light text-base text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Our services end */}

      {/* Online Shop */}
      <section className="bg-cream-brown">
        <div className="container mx-auto md:px-20 px-10 py-24 overflow-hidden">
          <div className="lg:flex items-center gap-16">
            <Fade cascade bottom>
              <div className="w-1/3 lg:block hidden">
                <div className="font-prata md:text-5xl text-4xl mb-8">
                  Order online
                </div>
                <div className="font-light text-base text-left mb-8">
                  Order your favorites from the comfort of your own home and get
                  it delivered right to your doorstep. Afterall, a day without
                  coffee is like… just kidding, we have no idea, so get your
                  coffee by whatever ‘beans’ necessary.
                </div>
                <div>
                  <Link to="https://www.talech.com/biz/ordering/825762/VAULT-COFFEEHOUSE-PITCAIRN-PA#/menu">
                    <Button filled text="Visit Shop" />
                  </Link>
                </div>
              </div>
            </Fade>

            <div className="lg:w-2/3 w-full">
              <OnlineShopSlider onlineShop={onlineShop} />
              <Fade bottom>
                <div>
                  <div className="progress-container w-full h-2 bg-primary-brown mt-5">
                    <div
                      className="progress-bar w-0 h-2 bg-black"
                      id="myBar"
                    ></div>
                  </div>
                </div>
              </Fade>
            </div>

            <Fade cascade bottom>
              <div className="w-full lg:hidden block text-center ">
                <div className="font-prata md:text-5xl text-4xl mb-8 mt-8">
                  Online shop
                </div>
                <div className="font-light text-base  mb-8">
                  Order your favorites from the comfort of your own home and get it delivered right to your doorstep. Afterall, a day without coffee is like… just kidding, we have no idea, so get your coffee by whatever ‘beans’ necessary.

                </div>
                <div>
                  <Link to="https://www.talech.com/biz/ordering/825762/VAULT-COFFEEHOUSE-PITCAIRN-PA#/menu">
                    <Button filled text="Visit Shop" />
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      {/* Online Shop END */}

      {/* Private Events */}
      <section>
        <BackgroundImage {...images.coffee_chat}>
          <div className="container mx-auto lg:px-20 px-10 py-52 mt-24 overflow-hidden">
            <Fade bottom>
              <div className="lg:w-1/2 md:w-3/4 w-full  bg-primary-brown/50  backdrop-blur-md p-10 lg:text-left text-center">
                <div className="font-prata md:text-5xl text-4xl	text-white mb-5 ">
                  Private Events
                </div>
                <div className="text-black  font-light  mb-8 ">
                  How would you like to have an area that smells like freshly
                  brewed coffee and buttered bagels all to yourself? Possibly a
                  place with rich culture and live music to keep you
                  entertained? Need we say more?
                </div>
                <div className="flex md:flex-row flex-col gap-5 lg:justify-start justify-center">
                  <div onClick={() => setModal(true)}>
                    <Button filled text="Inquire Now" green />
                  </div>
                  <Link to="/events/">
                    <Button bordered white text="Learn More" color="white" />
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </BackgroundImage>
      </section>
      {/* Private Events END */}

      {/* Good old entertainment */}

      <section>
        <div className="container mx-auto lg:px-20 px-10 py-24 overflow-hidden">
          <Fade cascade bottom>
            <div>
              <div className="font-prata  md:text-5xl text-4xl text-center text-black mb-5">
                Discovering local talent
              </div>
            </div>
            <div>
              <div className="font-light  text-black text-base text-center">
                Just like serving you amazing food & coffee, amazing music is
                also right up our alley!
              </div>
            </div>
            <div className="flex justify-center mt-8">
              <div>
                <Link to="/about/">
                  <Button bordered color="primary-brown" text="Learn More" />
                </Link>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="mt-14 ">
              <div className="relative">
                <div className="swiper-button-prev-custom absolute inset-y-0 flex items-center -left-5">
                  <button className="hover:p-7 duration-200 p-5 rounded-full bg-primary-brown relative z-10">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <ArrowLeftIcon className="w-5 text-white" />
                    </div>
                  </button>
                </div>
                <HomeSlider images={sliderImages} />
                <div className="swiper-button-next-custom absolute inset-y-0 flex items-center -right-5">
                  <button className="hover:p-7 duration-200 p-5 rounded-full bg-primary-brown relative z-10">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <ArrowRightIcon className="w-5 text-white" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="">
                <div className="my-custom-pagination-div flex mt-5 justify-center"></div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section>
        <div className="container mx-auto px-20 pb-24">
          <Fade bottom cascade>
            <div>
              <div className="font-prata  md:text-5xl text-4xl text-center text-black mb-5">
                Our sweet gallery
              </div>
            </div>
            <div>
              <div className="font-light  text-black text-base text-center">
                Here’s what you’ll enjoy at our cozy cafe.
              </div>
            </div>
          </Fade>
          <Fade bottom cascade>
            <div className="grid gap-5 md:grid-cols-4 grid-cols-2 mt-14">
              {gallery.map((item, index) => {
                return (
                  <GatsbyImage
                    key={index}
                    image={item.image}
                    alt="Vault Coffee"
                  />
                )
              })}
            </div>
          </Fade>
          <Fade bottom>
            <div className="flex justify-center mt-14">
              <Link to="/about/">
                <Button bordered color="primary-brown" text="Learn More" />
              </Link>
            </div>
          </Fade>
        </div>
      </section>
      {modal && (
        <section>
          <div className="h-screen w-full bg-black/50 fixed top-0 z-40 p-10 overflow-y-scroll">
            <Fade bottom duration={500}>
              <div className="max-w-6xl bg-white mx-auto p-10 rounded-xl relative">
                <div className="mb-10 font-semibold">
                  Please fill the below form to send your inquiry
                </div>
                <div className="absolute top-6 right-6">
                  <XIcon
                    className="w-7 hover:rotate-90 duration-200 cursor-pointer"
                    onClick={() => setModal(false)}
                  />
                </div>

                <InquiryForm
                  success_state={success}
                  onChange={toastHandleChange}
                />
              </div>
            </Fade>
          </div>
        </section>
      )}
      {success && (
        <div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      )}

      {/* Good old entertainment END */}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomepageImages {
    hero: file(relativePath: { eq: "pages/home/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    privateevent: file(relativePath: { eq: "pages/home/private-event.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    coffee_chat: file(relativePath: { eq: "home/coffee_chat.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid: file(relativePath: { eq: "shop-home-welcome.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }


    grid1: file(
      relativePath: { eq: "pages/home/online-shop-slider/001.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid2: file(
      relativePath: { eq: "pages/home/online-shop-slider/002.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid3: file(
      relativePath: { eq: "pages/home/online-shop-slider/003.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid4: file(
      relativePath: { eq: "pages/home/online-shop-slider/004.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid5: file(
      relativePath: { eq: "pages/home/online-shop-slider/005.png" }
    ) { childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid6: file(
      relativePath: { eq: "pages/home/online-shop-slider/006.png" }
    ) {childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid6: file(
      relativePath: { eq: "pages/home/online-shop-slider/006.png" }
    ) {childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    grid7: file(
      relativePath: { eq: "pages/home/online-shop-slider/007.png" }
    ) {childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    grid8: file( relativePath: { eq: "pages/home/online-shop-slider/008.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }


    online01: file( relativePath: { eq: "pages/home/order-online/001.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online02: file( relativePath: { eq: "pages/home/order-online/002.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online03: file( relativePath: { eq: "pages/home/order-online/003.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online04: file( relativePath: { eq: "pages/home/order-online/004.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online05: file( relativePath: { eq: "pages/home/order-online/005.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online06: file( relativePath: { eq: "pages/home/order-online/006.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online07: file( relativePath: { eq: "pages/home/order-online/007.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online08: file( relativePath: { eq: "pages/home/order-online/008.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online09: file( relativePath: { eq: "pages/home/order-online/009.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online10: file( relativePath: { eq: "pages/home/order-online/010.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online11: file( relativePath: { eq: "pages/home/order-online/011.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online12: file( relativePath: { eq: "pages/home/order-online/012.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    online13: file( relativePath: { eq: "pages/home/order-online/013.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }





    head1: file(relativePath: { eq: "home/head1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    head2: file(relativePath: { eq: "home/head2new.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
