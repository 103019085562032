import React, { useEffect } from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Fade from "react-reveal/Fade"

const OnlineShopSlider = props => {
  const handleScroll = event => {
    let scrEl = document.getElementById("scr-el")
    scrEl.addEventListener("scroll", event => {
      let scrolled =
        (scrEl.scrollLeft / (scrEl.scrollWidth - scrEl.clientWidth)) * 100
      console.log(scrolled)
      document.getElementById("myBar").style.width = scrolled + "%"
    })
  }

  useEffect(() => {
    const slider = document.querySelector(".scroll")
    let isDown = false
    let startX
    let scrollLeft

    slider.addEventListener("mousedown", e => {
      isDown = true
      slider.classList.add("active")
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener("mouseleave", () => {
      isDown = false
      slider.classList.remove("active")
    })
    slider.addEventListener("mouseup", () => {
      isDown = false
      slider.classList.remove("active")
    })
    slider.addEventListener("mousemove", e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = x - startX
      slider.scrollLeft = scrollLeft - walk
    })
  })

  return (
    <div
      className="overflow-x-scroll scrollbar-hide scroll cursor-grab"
      onScroll={handleScroll}
      id="scr-el"
    >
      <div className="grid grid-rows-2 grid-flow-col gap-5 w-full">
        {props.onlineShop.map((item, index) => {
          return (
            <Fade right>
              <div
                className={item.large ? "row-span-2 col-span-2" : ""}
                key={index}
              >
                <GatsbyImage
                  image={item.image}
                  className={item.large ? "w-[320px] h-full" : "w-[150px]"}
                  alt="Vault Coffee"
                />
              </div>
            </Fade>
          )
        })}
      </div>
    </div>
  )
}

export default OnlineShopSlider
