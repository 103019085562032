import { set, useForm } from "react-hook-form"
import db from "../../firebase-config"
import firebase from "firebase/compat/app"
import { useState } from "react"
import Fade from "react-reveal/Fade"
import React from "react"

const InquiryForm = props => {
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  function parentState() {
    props.onChange(true)
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  const submitContactRequest = async data => {
    let newData = Object.assign({}, data)
    newData.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    newData.isCompleted = false
    await db.collection("inquiries").add(newData)
  }

  const onSubmit = async data => {
    setLoading(true)
    try {
      await submitContactRequest(data)
      parentState()
      setLoading(false)
      // setSuccess(true)
      setSubmitError(null)
      reset()
    } catch {
      setLoading(false)
      setSubmitError("Submit Error occurred!")
      // setSuccess(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-10">
        <div className="font-light">How did you hear about us?</div>
        <input
          type="text"
          name="how"
          className="border-b-black border-b py-3 w-full focus:outline-none font-light "
          placeholder="Type your words here"
          {...register("how", {
            required: false,
          })}
        />
      </div>
      <div className="mb-10 flex w-full gap-10">
        <div className="w-full">
          <div className="font-light">
            First name <span className="text-red-500">*</span>
          </div>
          <input
            type="text"
            name="fName"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light"
            placeholder="Enter your first name"
            {...register("fName", {
              required: true,
              pattern: {
                value: /^[a-z ,.'-]+$/i,
                message: "You Can Only Use Letters",
              },
            })}
          />
          {errors.fName ? (
            <>
              {errors.fName.message ? (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    {errors.fName.message}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    First name cannot be empty
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
        <div className="w-full">
          <div className="font-light">Last name</div>
          <input
            type="text"
            name="lName"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light "
            placeholder="Enter your last name"
            {...register("lName", {
              required: false,
              pattern: {
                value: /^[a-z ,.'-]+$/i,
                message: "You Can Only Use Letters",
              },
            })}
          />
          {errors.lName ? (
            <>
              {errors.lName.message ? (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    {errors.lName.message}
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className="mb-10">
        <div className="font-light">
          Address <span className="text-red-500">*</span>
        </div>
        <div className="mb-5">
          <input
            type="text"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light "
            placeholder="Enter your address line 1"
            name="addresLine1"
            {...register("addresLine1", {
              required: true,
            })}
          />
          {errors.addresLine1 ? (
            <>
              {errors.addresLine1.message ? (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    {errors.addresLine1.message}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    Address line 1 cannot be empty
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
        <div className="mb-5">
          <input
            type="text"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light "
            placeholder="Enter your address line 2"
            name="addresLine2"
            {...register("addresLine2", {
              required: false,
            })}
          />
        </div>
        <div className="mb-5 flex w-full gap-10">
          <div className="w-full">
            <div className="font-light"></div>
            <input
              type="text"
              name="city"
              className="border-b-black border-b py-3 w-full focus:outline-none font-light"
              placeholder="City"
              {...register("city", {
                required: true,
              })}
            />
            {errors.city ? (
              <>
                {errors.city.message ? (
                  <>
                    <div className="text-xs text-red-400 font-firs-light mt-1">
                      {errors.city.message}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-xs text-red-400 font-firs-light mt-1">
                      City cannot be empty
                    </div>
                  </>
                )}
              </>
            ) : null}
          </div>
          <div className="w-full">
            <input
              type="text"
              name="region"
              className="border-b-black border-b py-3 w-full focus:outline-none font-light "
              placeholder="Region"
              {...register("region", {
                required: true,
              })}
            />
            {errors.region ? (
              <>
                {errors.region.message ? (
                  <>
                    <div className="text-xs text-red-400 font-firs-light mt-1">
                      {errors.region.message}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-xs text-red-400 font-firs-light mt-1">
                      Region cannot be empty
                    </div>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className="mb-10 flex w-full gap-10">
          <div className="w-full">
            <div className="font-light"></div>
            <input
              type="text"
              name="zipCode"
              className="border-b-black border-b py-3 w-full focus:outline-none font-light"
              placeholder="Postal / Zip Code"
              {...register("zipCode", {
                required: true,
              })}
            />
            {errors.zipCode ? (
              <>
                {errors.zipCode.message ? (
                  <>
                    <div className="text-xs text-red-400 font-firs-light mt-1">
                      {errors.zipCode.message}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-xs text-red-400 font-firs-light mt-1">
                      Postal / Zip Code cannot be empty
                    </div>
                  </>
                )}
              </>
            ) : null}
          </div>
          <div className="w-full">
            <input
              type="text"
              name="country"
              className="border-b-black border-b py-3 w-full focus:outline-none font-light "
              placeholder="Country"
              {...register("country", {
                required: true,
              })}
            />
            {errors.country ? (
              <>
                {errors.country.message ? (
                  <>
                    <div className="text-xs text-red-400 font-firs-light mt-1">
                      {errors.country.message}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-xs text-red-400 font-firs-light mt-1">
                      Country cannot be empty
                    </div>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mb-10">
        <div className="font-light">
          Email address <span className="text-red-500">*</span>
        </div>
        <input
          type="text"
          className="border-b-black border-b py-3 w-full focus:outline-none font-light "
          placeholder="Enter your email"
          name="email"
          {...register("email", {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Email address is not valid",
            },
          })}
        />
        {errors.email ? (
          <>
            {errors.email.message ? (
              <>
                <div className="text-xs text-red-400 font-firs-light mt-1">
                  {errors.email.message}
                </div>
              </>
            ) : (
              <>
                <div className="text-xs text-red-400 font-firs-light mt-1">
                  Email cannot be empty
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
      <div className="mb-10">
        <div className="font-light">
          Contact number <span className="text-red-500">*</span>
        </div>
        <input
          type="text"
          className="border-b-black border-b py-3 w-full focus:outline-none font-light "
          placeholder="Enter your contact number"
          name="number"
          {...register("number", {
            required: true,
            pattern: {
              value:
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
              message: "Number is not valid",
            },
          })}
        />
        {errors.number ? (
          <>
            {errors.number.message ? (
              <>
                <div className="text-xs text-red-400 font-firs-light mt-1">
                  {errors.number.message}
                </div>
              </>
            ) : (
              <>
                <div className="text-xs text-red-400 font-firs-light mt-1">
                  Number cannot be empty
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
      <div className="mb-10">
        <div className="font-light">Event Name</div>
        <input
          type="text"
          name="how"
          className="border-b-black border-b py-3 w-full focus:outline-none font-light "
          placeholder="Enter your event name here"
          {...register("how", {
            required: false,
          })}
        />
      </div>
      <div className="mb-10">
        <div className="font-light">
          Contact name and mobile on day(s) of event{" "}
          <span className="text-red-500">*</span>
        </div>
        <textarea
          type="text"
          className="border-b-black border-b py-3 w-full focus:outline-none font-light resize-none"
          placeholder="Enter your details here"
          name="eventContact"
          {...register("eventContact", {
            required: true,
          })}
          rows="5"
        ></textarea>
        {errors.eventContact ? (
          <>
            <div className="text-xs text-red-400 font-firs-light mt-1 mb-5">
              This field cannot be empty
            </div>
          </>
        ) : null}
      </div>
      <div className="mb-10">
        <div className="w-full">
          <div className="font-light">
            Event Date <span className="text-red-500">*</span>
          </div>
          <input
            type="text"
            name="date"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light"
            placeholder="DD/MM/YYYY"
            {...register("date", {
              required: true,
            })}
          />
          {errors.date ? (
            <>
              {errors.date.message ? (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    {errors.date.message}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    Date cannot be empty
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className="mb-10">
        <div className="w-full">
          <div className="font-light">
            Event Time <span className="text-red-500">*</span>
          </div>
          <input
            type="text"
            name="time"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light"
            placeholder="Enter your event time here"
            {...register("time", {
              required: true,
            })}
          />
          {errors.time ? (
            <>
              {errors.time.message ? (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    {errors.time.message}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    Time cannot be empty
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
      </div>
      <button
        className="bg-primary-brown px-10 py-3 border border-primary-brown text-white w-full hover:px-12 duration-500 text-center flex justify-center hover:w-11/12 mx-auto cursor-pointer"
        type="submit"
      >
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div>Submit</div>
        )}
      </button>
    </form>
  )
}

const Spinner = () => {
  return (
    <>
      <svg
        role="status"
        className="inline w-5 h-5 text-white animate-spin dark:text-white/50 fill-white/50 dark:fill-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </>
  )
}

export default InquiryForm
